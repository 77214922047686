<template>
  <div class="live-card left" @click="$emit('click')">
    <div>《{{data.topicName}}》</div>
    <div>
      <img :src="data.avatar | avatarFilter" alt="">
    </div>
    <div>{{data.teacherName}}-老师</div>
    <div>{{data.courseName}}</div>
    <div><span>{{data.beginDate}}</span> <span style="margin-left: 15px">{{data.startTime}}</span> </div>
    <div :class="{'yuxi': data.type ==='未开始', 'shangke': data.type ==='上课中', 'jieshu': data.type === '已结束'}">
      {{data.type|nameFilter}}
    </div>
      <div>{{data.type}}</div>
  </div>
</template>

<script>
export default {
  name: "LiveCard",
  props:{
    data: {
      type: Object,
      default: ()=>{},
    }
  },
  filters:{
    nameFilter(a){
      switch (a){
        case '未开始':
          return '预习'
        case '上课中':
          return '去上课'
        case '已结束':
          return '课程回顾'
      }
    },
    avatarFilter(a){
      if (a){
        return  a
      }else{
        return require('../assets/image/ic_teacher.png')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  @mixin positionTextStyle($top, $font-weight:400, $font-size:16px){
    width: 100%;
    position: absolute;
    top: $top;
    font-size: $font-size;
    font-weight: $font-weight;
    color: #FFFFFF;
    font-family: Microsoft YaHei;
  }


  .live-card{
    width: 292px;
    height: 355px;
    background: url("../assets/image/bg_zhibo.png") no-repeat;
    background-size: 100% 100%;
    margin: 25px;
    position: relative;
    &>:nth-child(1){
      @include positionTextStyle($top: 35px, $font-weight: bold);
    }
    &>:nth-child(2){
      width: 75px;
      height: 75px;
      position: absolute;
      top: 88px;
      left: 98px;
      img{
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }
    }
    &>:nth-child(3){
      @include positionTextStyle(185px)
    }
    &>:nth-child(4){
      @include positionTextStyle(216px, 400, 14px)
    }
    &>:nth-child(5){
      @include positionTextStyle(242px, 400, 14px);
      span{
        color: inherit;
      }
    }
    &>:nth-child(6){
      @include positionTextStyle(278px, bold, 17px);
      width: 210px;
      height: 47px;
      left: 45px;
      cursor: pointer;
      line-height: 45px;

      &.yuxi{
        background: url("../assets/image/bt_prepare.png") no-repeat;
        background-size: 100% 100%;
      }
      &.shangke{
        background: url("../assets/image/bt_class.png") no-repeat;
        background-size: 100% 100%;
      }
      &.jieshu{
        background: url("../assets/image/bt_lookback.png") no-repeat;
        background-size: 100% 100%;
      }
    }
    &>:nth-child(7){
      @include positionTextStyle(22px, 400, 12px);
      width: 50px;
      text-align: right;
      right: 20px;
    }
  }
</style>
