<template>
  <div class="live-title" :class="url === 1 ? 'live-img' : 'live-task'">
    {{title}}
  </div>
</template>

<script>
export default {
  name: "LiveTitle",
  props:{
    title: String,
    // 1 直播课 2 我的作业
    url: {
      type:Number,
      default:1
    }
  }
}
</script>

<style lang="scss" scoped>
  .live-title{
    position: absolute;
    width: 256px;
    height: 76px;
    background-size: 100% 100%;
    top: 45px;
    left: 413px;
    font-weight: bold;
    line-height: 76px;
    font-size: 22px;
    font-family: Microsoft YaHei;
    color: #FFFFFF;
  }
  .live-img{
    background: url('../assets/image/bt_zhibo.png') no-repeat;
  }
  .live-task {
    background: url('../assets/image/bt_zuoy(1).png') no-repeat;
  }
</style>
