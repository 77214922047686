<template>
  <div class="live page over-flow">
    <!--直播课-->
    <route-back/>
    <live-title title="直播课程"/>
    <my-timetable
      top="62"
      right="32"
      :calendar-list="calendarList.map(v=>v.beginDate)"
      @change="v=>gClassList(v)"
    />
    <div class="live__detail hidden-scroll"
         :class="{'live__detail-1': classList.length === 1, 'live__detail-2': classList.length === 2}">
      <live-card :data="i" v-for="(i, k) in classList" :key="k" @click="hCardClick(i)"/>
    </div>
  </div>
</template>

<script>
  import moment from 'moment'
  import RouteBack from "@/components/RouteBack";
  import LiveTitle from "@/components/LiveTitle";
  import MyTimetable from "@/components/MyTimetable";
  import LiveCard from "@/components/LiveCard";

  export default {
    name: "Live",
    components: {LiveCard, MyTimetable, LiveTitle, RouteBack},
    data() {
      // let reloadUrl;
      // if (process.env.NODE_ENV === 'development'){
      //   reloadUrl = 'http://192.168.20.19:8081/#/live'
      // }else if (process.env.NODE_ENV === 'production'){
      //   reloadUrl = 'http://pccomm.xweiart.com/#/live'
      // }else if (process.env.NODE_ENV === 'test'){
      //   reloadUrl = 'http://39.99.242.100:1664/#/video'
      // }
      return {
        classList: [],
        calendarList: [],
        l_interval: null,
      }
    },
    created() {
      this.gClassList()
      this.gCalendar()
      // console.log(this.a())
    },
    beforeDestroy() {
      clearInterval(this.l_interval)
    },
    methods: {
      async gClassList(beginDate = null) {
        this.$pageLoadingOpen()
        this.classList = await this.$_api('/online/adviser/dwsalecourse/showAllLiveLessonList', 'post', {
          beginDate,
        })
        this.$pageLoadingClose()
        this.computedTime()
      },

      computedTime() {
        this.l_interval = setInterval(() => {
          this.classList.forEach(v=>{
            if (v.type === '未开始') {
              console.log(this.getCourseChangeTime(v.sortTime))
              if ( this.getCourseChangeTime(v.sortTime) <= 10 ) {
                v.type = '上课中'
              }
            }
          })
        }, 2 * 1000)
      },


      // 返回格式为xx天xx小时xx分钟
      getCourseChangeTime(Time) {
        var stime = new Date();
        var etime = new Date(Time);
        // 两个时间戳相差的毫秒数
        var usedTime = etime - stime;
        // 计算相差的天数
        var days = Math.floor(usedTime / (24 * 3600 * 1000));
        // 计算天数后剩余的毫秒数
        var leave1 = usedTime % (24 * 3600 * 1000);
        // 计算出小时数
        var hours = Math.floor(leave1 / (3600 * 1000));
        // 计算小时数后剩余的毫秒数
        var leave2 = leave1 % (3600 * 1000);
        // 计算相差分钟数
        var minutes = Math.floor(leave2 / (60 * 1000));
        return minutes;
      },


      async gCalendar() {
        this.calendarList = await this.$_api('/online/adviser/dwsalecourse/showLiveTime', 'post', {})
      },
      async hCardClick(i) {
        if (i.type === '未开始') {
          // const token = JSON.parse(localStorage.getItem('login-info')).token
          await this.$router.push({path: '/live-pre', query: {topicId: i.topicId, tn: i.topicName}})
          // window.WCRClassRoom.openUrlByExternal(this.reloadUrl + `?t=${token}&i=${i.topicId}`)
          // window.open(this.reloadUrl + `?t=${token}&i=${i.topicId}`)
        } else if (i.type === '上课中') {
          if ( await this.getApiverification(i) ) {
            await this.goIn(2, i)
          }else {
            this.$message.error('课程已结束')
            await this.gClassList()
          }
        } else {
          await this.goIn(3, i)
        }
      },

      async getApiverification(i){
       return await this.$_api(`/online/app/attendance/verification/${i.classRoomId}`)
      },

      async gSendCallback(res){
        await this.$_api('/online/app/courseTimeTable/insertNotify', 'post', res)
      },

      async goIn(status, i) {
        const _that = this;
        _that.$pageLoadingOpen()
        let token = await this.$_api(`/online/app/check/getToken/${i.classRoomId}`)


        let userInfo = JSON.stringify({
          id: i.studentIdCode,
          type: 'student',
          name: i.studentName,
          token: token,
          avatar: i.avatar,
        })

        window.WCRClassRoom.setUser(userInfo)


        let joinInfo = JSON.stringify({
          institutionId: 65461,
          id: i.classRoomId,
          title: i.topicName,
          teacher_id: i.teacherHashCode.toString(),
          teacher_name: i.teacherName,
          scenario: '7',
          startTime: i.beginDate + ' ' + i.startTime + ':00',
          endTime: i.beginDate + ' ' + i.endTime + ':00',
          status: status,
        })
        let callFunc = function (res) {
          // alert(JSON.stringify(res))
           _that.gSendCallback(res)
          if (res.notify === 'startClass') {
            let data = {
              "notify": "startClass",
              "body": {
                "status": "1",
                "data": {
                  "lesson": res.body.room_id,
                  "actual_time": moment().format('YYYY-MM-DD HH:mm:ss'),
                  "server_time": moment().format('YYYY-MM-DD HH:mm:ss')
                }
              }
            }
            window.WCRClassRoom.commonNotifyCallback(0, data)

          } else if (res.notify === 'endClass') {
            let data = {
              "notify": "endClass",
              "body": {
                "status": "1",
                "data": {
                  "lesson": res.body.room_id
                }
              }
            }
            window.WCRClassRoom.commonNotifyCallback(0, data)

            _that.$router.push({
              name: 'After',
              query: {
                id: res.body.room_id
              }
            })
          }

        }

        window.WCRClassRoom.joinClassRoom(joinInfo, callFunc)
        _that.$pageLoadingClose()

      }
    }
  }
</script>

<style lang="scss" scoped>
  .live {
    position: relative;
    background: url("../assets/image/bg-zb.png") no-repeat;
    background-size: 100% 100%;
    overflow-y: hidden;

    .live__detail {
      width: calc(100% - 20px);
      height: 480px;
      overflow-y: scroll;
      position: absolute;
      top: 171px;
      padding-left: 20px;

      &.live__detail-1 {
        width: 320px;
        left: 360px;
      }

      &.live__detail-2 {
        width: 700px;
        left: 220px;
      }
    }
  }
</style>
